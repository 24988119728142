import { Button, Layout, theme } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import HeaderComponent from "./components/header";
import Menu from "./components/menu";
import "./style.css";
import Charts from "./components/charts";
const { Header, Content, Footer, Sider } = Layout;
const App = () => {
  const isDesktop = window.innerWidth > 768;
  const elementRef = useRef(null);
  const [width, setWidth] = useState('306')

  const [open, setOpen] = useState(isDesktop);
  const {
    token: { colorBgContainer },
  } = theme.useToken({});

  function siderController(state = undefined) {
    if (state === undefined) {
      setOpen(!open);
    } else {
      setOpen(state);
    }
  }

  /*
  const resizer = document.querySelector("#resizer");
  const sidebar = document.querySelector(".sidebr");

  // window.addEventListener("DOMContentLoaded", (event) => {

  //   resizer.addEventListener("mousedown", (event) => {
  //     console.log('arun')

  //   });
  // });

  const getSizeOfSideBat = (e) => {
    document.addEventListener("mousemove", resize, false);
    document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", resize, false);
    }, false);
  };

  function resize(e) {
    console.log("Mouse Dowm");
    const size = `${e.x}px`;
    console.log("size", size);
    console.log(sidebar);
    //sidebar.style.flexBasis = size;
  }
*/
  /*
  const resizer = document.querySelector("#resizer");
  const sidebar = document.querySelector(".sidebr");

  resizer.addEventListener("mousedown", (event) => {
    document.addEventListener("mousemove", resize, false);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", resize, false);
      },
      false
    );
  });

  function resize(e) {
    const size = `${e.x}px`;
    sidebar.style.flexBasis = size;
  }

  sidebar.style.flexBasis = "325px";
*/
  useEffect(() => {
    const resizer = document.querySelector("#resizer");
    const sidebar = document.querySelector(".sidebr");

    const resize = (e) => {
      const size = `${e.clientX}px`;
      sidebar.style.flexBasis = size;
    };

    const handleMouseDown = (event) => {
      document.addEventListener("mousemove", resize, false);
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", resize, false);
      });
    };

    if (resizer && sidebar) {
      resizer.addEventListener("mousedown", handleMouseDown);
      // sidebar.style.flexBasis = "325px";
    }
    return () => {
      if (resizer && sidebar) {
        resizer.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, []);


  return (
    <>
      <div className="bdy">
        <div className="wraps">
          <Layout className="conti" id="sidbarWalaMenu">
            <Sider
              width={open ? (isDesktop ? width : "100vw") : 0}
              theme="light"
              className="sidebr"
              ref={elementRef}
            // id="sidebar"
            >
              <Button
                onClick={() => siderController()}
                className="sider-controller"
              >
                <MenuOutlined />
              </Button>
              <Menu siderController={siderController} setWidth={setWidth} width={width} />
            </Sider>
            <div
              id="resizer"
            ></div>
            <Layout
              className="site-layout main"
              style={{
                marginLeft: open ? width : 0,
                overflow: "auto",
              }}
            >
              <Header
                style={{
                  padding: 0,
                  background: colorBgContainer,
                  position: "sticky",
                  top: 0,
                  zIndex: 3,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  height: "initial",
                }}
              >
                <HeaderComponent siderController={siderController} />
              </Header>
              <Content
                style={{
                  zIndex: 2,
                  margin: isDesktop ? "10px" : "100px 16px 0",
                  padding: 24,
                  minHeight: "initial",
                  maxHeight: "initial",
                  height: "initial",
                }}
              >
                <Charts siderController={siderController} />
              </Content>
              <Footer
                style={{
                  textAlign: "center",
                }}
              >
                Soshianest ©{new Date().getFullYear()}
              </Footer>
            </Layout>
          </Layout>
        </div>
      </div>
    </>
  );
};
export default App;
